<template>
  <form @submit.prevent="onSave">
    <ODNForm>
      <!-- Sector -->
      <ODNFormField :label="$t('fields.sector.label')">
        <ion-select
          v-if="!sectorsLoading"
          v-model="form.sectorId"
          :placeholder="$t('fields.sector.placeholder')"
          :cancelText="$t('buttons.cancel')"
          :okText="$t('buttons.validate')"
        >
          <ion-select-option
            v-for="sector in sectors"
            :key="sector.id"
            :value="sector.id"
          >
            {{ sector.name }}
          </ion-select-option>
        </ion-select>
      </ODNFormField>

      <!-- Contact lastname -->
      <ODNFormField
        :label="$t('fields.lastname.label')"
        label-position="floating"
        :error="isSubmitted && errors.lastname ? $t('errors.required') : null"
      >
        <ion-input v-model="form.lastname"></ion-input>
      </ODNFormField>

      <!-- Contact firstname -->
      <ODNFormField
        :label="$t('fields.firstname.label')"
        label-position="floating"
        :error="isSubmitted && errors.firstname ? $t('errors.required') : null"
      >
        <ion-input v-model="form.firstname"></ion-input>
      </ODNFormField>

      <!-- Contact phone -->
      <ODNFormField :label="$t('fields.phone.label')" label-position="floating">
        <ion-input v-model="form.phone"></ion-input>
      </ODNFormField>

      <!-- Contact email -->
      <ODNFormField :label="$t('fields.email.label')" label-position="floating">
        <ion-input v-model="form.email" type="email"></ion-input>
      </ODNFormField>

      <!-- Actions -->
      <div class="odn-mat-16">
        <ion-button
          type="submit"
          color="primary"
          :disabled="saving"
          expand="block"
        >
          {{ $t('buttons.validate') }}
          <ion-icon slot="end" :icon="icons.checkmark"></ion-icon>
        </ion-button>
      </div>
    </ODNForm>
  </form>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  toastController,
} from '@ionic/vue';
import { checkmark } from 'ionicons/icons';

import ODNForm from '@c/odn-form.vue';
import ODNFormField from '@c/odn-form-field.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonInput,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonIcon,
    ODNForm,
    ODNFormField,
  },
  props: {
    storeId: {
      type: Number,
      default: null,
    },
    contactId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: null,
        storeId: parseInt(this.storeId),
        sectorId: null,
        lastname: null,
        firstname: null,
        phone: null,
        email: null,
      },
      isSubmitted: false,
      loading: false,
      saving: false,
      sectors: [],
      sectorsLoading: true,
      selectedSector: null,
      icons: {
        checkmark,
      },
    };
  },
  computed: {
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    errors() {
      return {
        lastname: !this.form.lastname,
        firstname: !this.form.firstname,
      };
    },
  },
  created() {
    if (this.contactId) {
      this.fetchContact();
    }
    this.fetchSectors();
  },
  emits: ['saved', 'cancel'],
  methods: {
    async fetchContact() {
      this.loading = true;
      try {
        const contact = (await APIService.get(`/contacts/${this.contactId}`))
          .data;
        this.form.id = contact.id;
        this.form.storeId = contact.store.id;
        if (contact.sector) {
          this.form.sectorId = contact.sector.id;
        }
        this.form.lastname = contact.lastname;
        this.form.firstname = contact.firstname;
        this.form.phone = contact.phone;
        this.form.email = contact.email;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.contact.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchSectors() {
      this.sectorsLoading = true;
      try {
        this.sectors = (await APIService.get(`/sectors?filter=light`)).data;
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.sectors.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.sectorsLoading = false;
      }
    },
    async onSave() {
      this.isSubmitted = true;

      const hasErrors = Object.values(this.errors).some(e => e);

      if (hasErrors) return;

      this.saving = true;

      if (this.contactId) {
        try {
          await APIService.put(`/contacts/${this.contactId}`, this.form);
          const toast = await toastController.create({
            message: this.$t('messages.contact.put.success'),
            color: 'success',
            duration: 2000,
          });
          toast.present();
          this.$emit('saved', this.contactId);
        } catch (err) {
          const toast = await toastController.create({
            message: this.$t('messages.contact.put.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        } finally {
          this.saving = false;
        }
      } else {
        try {
          const id = (await APIService.post(`/contacts`, this.form)).data.id;

          const toast = await toastController.create({
            message: this.$t('messages.contact.post.success'),
            color: 'success',
            duration: 2000,
          });
          toast.present();

          this.$emit('saved', id);
        } catch (err) {
          const toast = await toastController.create({
            message: this.$t('messages.contact.post.error'),
            color: 'danger',
            duration: 2000,
          });
          toast.present();
        } finally {
          this.saving = false;
        }
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
